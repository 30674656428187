@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full bg-gray-100;

    color: theme("colors.brand.green.dark");
  }

  body,
  #root {
    color: theme("colors.brand.green.dark");
    @apply h-full bg-gray-100;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: theme("fontFamily.display");
  }

  a,
  .link {
    color: theme("colors.brand.orange.medium");
  }

  a:hover,
  .link:hover {
    color: theme("colors.brand.orange.bright");
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }
}

@layer utilities {
  .brand-hero {
    background: radial-gradient(
      104.43% 92.28% at 11.11% -9.73%,
      rgb(67 201 151 / 94%) 0%,
      #216769 100%
    );
    background-color: white;
  }
}
